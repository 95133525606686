import { AxiosError } from "axios"
import * as _ from "lodash-es"
import toast from "react-hot-toast"
import { useTranslation } from "react-i18next"
import { useMutation, UseMutationOptions, UseMutationResult } from "react-query"

import { EOrganizationTypes } from "src/constants"
import { TLanguageCodes } from "types"
import { fetchJson } from "utils/network"

import { ICertificate } from "./useCertificate"

type TData = ICertificate
type TError = unknown
export type CreateCertificatePayload = {
  BeurteilterMitarbeiter: {
    ID: number
  }
  CatSprache: {
    ID: TLanguageCodes
  }
  CatZeugnisTyp: {
    ID: number
  }
  CatStandort: {
    ID: number
  }
  LocalHRBenutzer?: {
    ID: number
  }
  LineManagerBenutzer?: {
    ID: number
  }
}
type THook = (
  options?: UseMutationOptions<TData, TError, CreateCertificatePayload>
) => UseMutationResult<TData, TError, CreateCertificatePayload>

export const useCreateCertificate: THook = (options) => {
  const { t } = useTranslation("toastComponent")

  let toastId: ReturnType<typeof toast.loading>

  return useMutation(
    async (payload) =>
      fetchJson<TData>(`zeugnisse`, {
        method: "POST",
        body: {
          ...payload,
          ZeugnisBearbeiter: [],
          UseSectionInFunctions: true,
          OrganisationsEinheitTyp: EOrganizationTypes.DEFAULT,
        },
      }),
    {
      onMutate(...args) {
        toastId = toast.loading(t("inProgress"))

        options?.onMutate?.(...args)
      },
      onError(...args) {
        const error = args[0] as AxiosError<{ Message?: string }>
        let errorMessage = t("error")
        if (error.response?.data?.Message === "EMPLOYEE_DOEST_HAVE_JOB") {
          errorMessage = t("errors.employeeNoJob")
        }
        toast.error(errorMessage, { id: toastId })

        void options?.onError?.(...args)
      },
      onSuccess(...args) {
        toast.success(t("done"), { id: toastId })

        const { analytics } = window

        if (analytics) {
          void analytics.track("New certificate created", {
            certificateId: args[0].ZeugnisID,
          })
        }

        void options?.onSuccess?.(...args)
      },
      ..._.omit(options, ["onMutate", "onError", "onSuccess"]),
    }
  )
}
