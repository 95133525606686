import * as _ from "lodash-es"
import { useMutation, UseMutationOptions, UseMutationResult } from "react-query"

import { fetchJson } from "utils/network"

type TData = void
type TError = unknown
type TPayload = {
  email: string
}
type THook = (
  options?: UseMutationOptions<TData, TError, TPayload>
) => UseMutationResult<TData, TError, TPayload>

export const useRequestResetPasswordEmail: THook = (options) =>
  useMutation(
    ({ email }) =>
      fetchJson<TData>(`benutzer/resetpasswordrequest`, {
        method: "POST",
        body: {
          Email: email,
        },
      }),
    {
      onMutate(...args) {
        const { analytics } = window

        if (analytics) {
          void analytics.track("User requested password to be reset")
        }

        options?.onMutate?.(...args)
      },
      ..._.omit(options, ["onMutate"]),
    }
  )
