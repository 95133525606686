import * as _ from "lodash-es"
import toast from "react-hot-toast"
import { useTranslation } from "react-i18next"
import { useMutation, UseMutationOptions, UseMutationResult } from "react-query"

import { fetchJson } from "utils/network"

type TData = void
type TError = unknown
type TPayload = {
  Name: string
  CertificateType: string
  ManagerName: string
  Notes: string
}
type THook = (
  options?: UseMutationOptions<TData, TError, TPayload>
) => UseMutationResult<TData, TError, TPayload>

export const useRequestCertificate: THook = (options) => {
  const { t } = useTranslation("toastComponent")

  let toastId: ReturnType<typeof toast.loading>

  return useMutation(
    (payload) =>
      fetchJson<TData>("certificaterequest", {
        method: "POST",
        body: payload,
      }),
    {
      onMutate(payload) {
        toastId = toast.loading(t("inProgress"))

        const { analytics } = window

        if (analytics) {
          void analytics.track("User requested certificate")
        }

        options?.onMutate?.(payload)
      },
      onError(...args) {
        toast.error(t("error"), { id: toastId })

        void options?.onError?.(...args)
      },
      onSuccess(...args) {
        toast.success(t("done"), { id: toastId })

        void options?.onSuccess?.(...args)
      },
      ..._.omit(options, ["onMutate", "onError", "onSuccess"]),
    }
  )
}
