import { useQuery, UseQueryOptions, UseQueryResult } from "react-query"

import { EOrganizationTypes } from "src/constants"
import { fetchJson } from "utils/network"

export type TSystemInfo = {
  AllowRequestCertificate: boolean
  AllowBasicUsersToDoManualEdit: boolean
  AllowReorderingEvaluations: boolean
  ShowEvaluationSubcategory: boolean
  AssemblyVersion: string
  AzureAdAuthority: string
  AzureAdClientId: string
  DataBase: string
  DatabasePerformanceTestSeconds: number
  DatabaseRevision: string
  DisplayJob100Degree: boolean
  EnableLanguageEn: boolean
  EnableLanguageGe: boolean
  EnableLanguageFr: boolean
  EnableLanguageIt: boolean
  Environment: string
  Instance: string
  LizenzTyp: string
  MaintenanceFrom: string
  MaintenanceFromTo: string
  MaintenanceTo: string
  MaxEvaluations: number
  OrganisationsEinheitTyp: EOrganizationTypes
  ServerPerformanceTestSeconds: number
  TemplateCacheLoadSeconds: number
  UseFormOfAddress: boolean
  UseCustomTasks: boolean
  WebApiActionDurationSeconds: number
  GoogleUserInfoEndpoint: boolean
  UseComplexPasswordRules: boolean
  ResetPasswordPeriodically: boolean
  Feature1: boolean
  Feature2: boolean
  Feature3: boolean
}

type TData = TSystemInfo
type THook = (options?: UseQueryOptions<TData>) => UseQueryResult<TData>

export const useSystemInfo: THook = (options) =>
  // @ts-ignore
  useQuery("SYSTEM_INFO", () => fetchJson<TData>("system"), options)
