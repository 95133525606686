import { useQuery } from "react-query"

import { EGenders } from "src/constants"
import { TLanguageCodes, IItem } from "types"
import { operations } from "types/apiSchema"
import { fetchJson } from "utils/network"

import { closingStatementsKeys } from "./keys"
import { ECertificateTypes } from "../useCertificateTypes"

type TQueryParams =
  operations["CatSchlussformelBlocks_GetList"]["parameters"]["query"]

export enum EClosingStatementGroups {
  // NOTE:
  // This group contains only one sentence, e.g.
  // "Nicole Dulder leaves our company on the 30 November 2020.",
  // which is always enabled for the work certificate.
  EXIT_1 = 1,
  EXIT = 2,
  WISHES = 3,
  TASKS = 4,
  SECTION_INTRODUCTIONS = 5,
}

export type TClosingStatementInGroup = {
  CatSchlussformelOptionID: number
  Sequenz: number
  CatZeugnisTyp: IItem
  CatSatzTemplate: IItem
  IsInUse: boolean
  Code: string
  ModDate: string
}

export type TClosingStatementGroup = {
  SchlussformelOptionen: TClosingStatementInGroup[]
  CatSchlussformelBlockID: EClosingStatementGroups
  Code: string
  Sequenz: number
  ModDate: string
} | null

type TData = TClosingStatementGroup[]
export type TClosingStatementsGroupParams = {
  certificateType?: ECertificateTypes
  certificateId?: number
  jobId?: number
  gender?: EGenders
  language?: TLanguageCodes
}

function getData({
  certificateId,
  certificateType,
  gender,
  language,
  jobId,
}: TClosingStatementsGroupParams) {
  if (!certificateId || !certificateType || !gender || !language || !jobId) {
    return Promise.reject(new Error("Missing params"))
  }

  return fetchJson<TData, false, TQueryParams>("catschlussformelblocks", {
    queryParams: {
      filterAndRenderZeugnisTypId: certificateType,
      renderGeschlechtId: gender as unknown as number,
      renderSpracheId: language,
      certificateId: certificateId,
      jobId: jobId,
    },
  })
}

export const useClosingStatementsGroups = (
  params: TClosingStatementsGroupParams
) =>
  useQuery({
    queryKey: closingStatementsKeys.allGroups(params),
    queryFn: () => getData(params),
    enabled: Boolean(
      params.certificateType &&
        params.gender &&
        params.language &&
        params.certificateId
    ),
  })
