// @ts-ignore
import { Transition, Dialog } from "@headlessui/react"
import classNames from "classnames"
import { Fragment, ReactNode } from "react"
import { useTranslation } from "react-i18next"

import Button from "components/elements/Button"
import useBlurMainSection from "hooks/useBlurMainSection"
import useLockBodyScroll from "hooks/useLockBodyScroll"

type TProps = {
  children?: ReactNode
  show: boolean
  onClose: () => void
  onConfirmButtonClick?: () => void
  title?: string | null
  subtitle?: string | null
  cancelButtonText?: string | null
  confirmButtonText?: string | null
  confirmButtonProps?: {
    disabled?: boolean
  }
  width?: "lg" | "xl" | "2xl"
  CustomConfirmButton?: ReactNode
  className?: string
}

export default function Modal(props: TProps): JSX.Element {
  const { t } = useTranslation("modalComponent")

  const {
    children: body,
    show,
    onClose,
    onConfirmButtonClick = () => {},
    title,
    subtitle,
    cancelButtonText = t("cancel"),
    confirmButtonText = t("confirm"),
    confirmButtonProps,
    width,
    CustomConfirmButton,
    className,
  } = props

  useLockBodyScroll()
  useBlurMainSection()

  let modalWidth = "max-w-md"

  if (width) {
    switch (width) {
      case "lg":
        modalWidth = "max-w-lg"
        break
      case "xl":
        modalWidth = "max-w-xl"
        break
      case "2xl":
        modalWidth = "max-w-2xl"
        break
      default:
        break
    }
  }

  return (
    <Transition appear {...{ show }} as={Fragment}>
      <Dialog
        as="div"
        className={classNames(["Modal", "fixed inset-0 z-top overflow-y-auto"])}
        onClose={onClose}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              onClick={(e) => e.stopPropagation()}
              className={classNames([
                className,
                "inline-block",
                "w-full",
                modalWidth,
                "my-8 transform space-y-6",
                "overflow-y-visible",
                "rounded-2xl border",
                "border-black bg-white",
                "p-6 text-left align-middle transition-all",
              ])}
            >
              <div>
                {title && (
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    {title}
                  </Dialog.Title>
                )}
                {subtitle && (
                  <Dialog.Description className="mt-2 text-sm text-gray-500">
                    {subtitle}
                  </Dialog.Description>
                )}
              </div>

              <div>{body}</div>

              <div className="flex justify-end space-x-4">
                <Button secondary onClick={onClose}>
                  {cancelButtonText}
                </Button>
                {CustomConfirmButton ?? (
                  <Button
                    onClick={onConfirmButtonClick}
                    {...confirmButtonProps}
                  >
                    {confirmButtonText}
                  </Button>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}
