import { useQuery, UseQueryOptions, UseQueryResult } from "react-query"

import { IUser } from "types"
import { fetchJson } from "utils/network"

import { userKeys } from "./keys"

type TData = IUser
type THook = (
  id: number,
  options?: UseQueryOptions<TData>
) => UseQueryResult<TData>

export const useUser: THook = (id, options) =>
  // @ts-ignore
  useQuery(userKeys.one(id), () => fetchJson<IUser>(`Benutzer/${id}`), options)
