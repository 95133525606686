import { Transition, Portal } from "@headlessui/react"
import classNames from "classnames"
import { KeyboardEvent, ReactNode } from "react"
import { useTranslation } from "react-i18next"

import { Cancel as CancelIcon } from "assets/icons"
import useBlurMainSection from "hooks/useBlurMainSection"
import useLockBodyScroll from "hooks/useLockBodyScroll"

import Button from "./Button"

type TDrawerProps = {
  show: boolean
  onClose: () => void
  children?: ReactNode
  position?: "left" | "right"
  narrow?: boolean
  extraHeaderButtons?: ReactNode
  footer?: ReactNode
  className?: string
}

export function Drawer(props: TDrawerProps): JSX.Element {
  const {
    show,
    onClose,
    position = "right",
    narrow = false,
    children,
    extraHeaderButtons,
    footer,
    className,
  } = props

  const { t } = useTranslation("drawerComponent")

  useLockBodyScroll()
  useBlurMainSection()

  return (
    <Portal>
      <Transition
        show={show}
        className="fixed left-0 top-0 h-full w-full"
        // FIXME:
        // Transition executes several times after updating field values
        // with data fetched from the server.
        // This essentially causes the drawer to jump back and forth, causing flicker.
        // Observed in @headlessui/react@1.4.0

        // enter="transition ease-in-out duration-400 transform"
        // leave="transition ease-in-out duration-400 transform"
        // enterTo="translate-x-0"
        // leaveFrom="translate-x-0"
        // enterFrom={
        //   position === "right" ? "translate-x-full" : "-translate-x-full"
        // }
        // leaveTo={position === "right" ? "translate-x-full" : "-translate-x-full"}
        tabIndex={0}
        onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
          if (event.code === "Escape") {
            onClose()
          }
        }}
      >
        {/* <div className="fixed top-0 left-0 h-full w-full"> */}
        <div
          className={classNames([
            "Drawer relative z-10 flex h-full w-full",
            className,
          ])}
        >
          <div
            className="DrawerOverlay h-full w-full"
            onClick={onClose}
            onKeyDown={onClose}
            role="button"
            tabIndex={0}
            aria-label="Close"
          />

          <div
            className={classNames([
              "DrawerContent",
              `absolute top-0 ${position}-0`,
              "grid",
              "h-full w-full",
              "z-50",
              "border-l border-r",
              "border-black",
              "bg-white",
              narrow ? "max-w-[30rem]" : "max-w-[50rem]",
            ])}
            style={{
              gridTemplateRows: "max-content 1fr max-content",
            }}
          >
            <header
              className={classNames([
                "z-50 flex justify-end",
                "gap-4",
                "border-b",
                "border-black bg-white",
                "p-4",
              ])}
            >
              {extraHeaderButtons}
              <Button secondary onClick={onClose} Icon={CancelIcon}>
                <span>{t("close")}</span>
              </Button>
            </header>

            {/* CHILDREN */}
            <div className="overflow-y-auto">{children}</div>

            {/* FOOTER */}
            {footer && (
              <footer
                className={classNames([
                  "flex justify-end gap-4",
                  "border-t",
                  "border-black",
                  "bg-white p-4",
                ])}
              >
                {footer}
              </footer>
            )}
          </div>
        </div>
      </Transition>
    </Portal>
  )
}

type TDrawerSectionRowProps = {
  children: ReactNode
  columns?: 2 | 4
  className?: string
}

export function DrawerSectionRow(props: TDrawerSectionRowProps): JSX.Element {
  const { children, columns, className } = props

  let gridColumnClass = ""

  switch (columns) {
    case 2:
      gridColumnClass = "grid-cols-2"
      break
    case 4:
      gridColumnClass = "grid-cols-4"
      break
    default:
      break
  }

  return (
    <div
      className={classNames([
        "grid grid-flow-col items-baseline gap-8",
        gridColumnClass,
        className,
      ])}
    >
      {children}
    </div>
  )
}
