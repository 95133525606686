import * as _ from "lodash-es"
import { toast } from "react-hot-toast"
// import { toast } from 'react-toastify';
import { useTranslation } from "react-i18next"
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from "react-query"

import { EEvaluationCategoryCodes } from "hooks/data"
import { fetchJson } from "utils/network"

import { certificateKeys } from "./keys"
import { ICertificate } from "./useCertificate"

type TData = {
  ZeugnisID: number
  Beurteilungen: TBeurteilungen[]
  BeurteilungsTexte: {
    CatBereichID: number
    Code: string
    BeurteilungsTexte: {
      CatKriteriumID: number
      CatBeurteilungLevelID: number
      CatBeurteilungsOptionID: number
      Text: string
    }[]
  }[]
  BeurteilungsStatistik: {
    Code: EEvaluationCategoryCodes
    AnzahlAussagenSoll: number
    AnzahlAussagenIst: number
  }[]
}

type TError = {
  Message: string
}

type TBeurteilungen = {
  CatBeurteilungsOptionID: number
  UseCustomTemplate: boolean
  CustomTemplate: string
}

type TPayload = {
  certificateId: number
  payload: {
    Beurteilungen: TBeurteilungen[]
  }
}

type TContext = { previousCertificate?: ICertificate }
type THook = (
  options?: UseMutationOptions<TData, TError, TPayload, TContext>
) => UseMutationResult<TData, TError, TPayload, TContext>

let ongoingMutations = 0

export const useEvaluationsMutation: THook = (options) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation("toastComponent")

  let toastId: ReturnType<typeof toast.loading>

  return useMutation(
    ({ certificateId, payload }) =>
      // @ts-ignore
      fetchJson<TData>(`Zeugnisse/${certificateId}/PutEvaluations`, {
        method: "PUT",
        body: payload,
      }),
    {
      async onMutate({ certificateId, payload }) {
        ongoingMutations += 1
        const key = certificateKeys.one(certificateId)
        await queryClient.cancelQueries(key)

        const previousCertificate = queryClient.getQueryData<ICertificate>(key)

        if (previousCertificate) {
          queryClient.setQueryData(key, {
            ...previousCertificate,
            Beurteilungen: payload.Beurteilungen,
          })
        }

        return { previousCertificate }
      },
      onError(...args) {
        const [, { certificateId }, ctx] = args
        ongoingMutations -= 1

        if (ctx?.previousCertificate) {
          queryClient.setQueryData(
            certificateKeys.one(certificateId),
            ctx.previousCertificate
          )
        }

        toast.error(t("error"), { id: toastId })

        void options?.onError?.(...args)
      },
      onSuccess(...args) {
        ongoingMutations -= 1

        // const [data] = args

        // const key = certificateKeys.one(data.ZeugnisID)
        // const previousCertificate = queryClient.getQueryData<ICertificate>(key)

        // queryClient.setQueryData(key, {
        //   ...previousCertificate,
        //   ...data,
        // })

        void options?.onSuccess?.(...args)
      },
      onSettled: (data) => {
        if (ongoingMutations === 0) {
          const key = certificateKeys.one(data?.ZeugnisID)
          void queryClient.invalidateQueries(key)
        }
      },
      ..._.omit(options, ["onMutate", "onError", "onSuccess"]),
    }
  )
}
