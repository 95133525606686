import * as _ from "lodash-es"
import { toast } from "react-hot-toast"
import { useTranslation } from "react-i18next"
import { useMutation, UseMutationOptions, UseMutationResult } from "react-query"
import { Object } from "ts-toolbelt"

import { definitions, operations } from "types/apiSchema"
import { fetchJson } from "utils/network"

import { TAltCompany } from "./useAltCompanies"

type TData = TAltCompany
type TError = unknown
type TPayload = {
  CatFirmaID: NonNullable<
    definitions["CompanyAlternativeNameDto"]["CatFirmaID"]
  >
  NameML: Object.Required<
    definitions["CompanyAlternativeNameDto"]["NameML"],
    "de" | "fr" | "it" | "en"
  >
  SubtitleML: Object.Required<
    definitions["CompanyAlternativeNameDto"]["SubtitleML"],
    "de" | "fr" | "it" | "en"
  >
}
type TQueryParams =
  operations["CompanyAlternativeNames_Post"]["parameters"]["query"]
type TArgs = {
  queryParams?: TQueryParams
  options?: UseMutationOptions<TData, TError, TPayload>
}
type THook = (args: TArgs) => UseMutationResult<TData, TError, TPayload>

export const useCreateAltCompany: THook = ({ queryParams, options }) => {
  const { t } = useTranslation("toastComponent")

  let toastId: ReturnType<typeof toast.loading>

  return useMutation(
    (payload: TPayload) =>
      fetchJson<TData, false, TQueryParams>("companyalternativenames", {
        method: "POST",
        body: payload,
        queryParams: {
          renderSpracheId: "de",
          ...queryParams,
        },
      }),
    {
      onMutate(...args) {
        toastId = toast.loading(t("inProgress"))

        options?.onMutate?.(...args)
      },
      onError(...args) {
        toast.error(t("error"), { id: toastId })

        void options?.onError?.(...args)
      },
      onSuccess(...args) {
        toast.success(t("done"), { id: toastId })

        void options?.onSuccess?.(...args)
      },
      ..._.omit(options, ["onMutate", "onError", "onSuccess"]),
    }
  )
}
