import { AxiosResponse } from "axios"
import {
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useInfiniteQuery,
} from "react-query"

import { TODataParams } from "types"
import { operations } from "types/apiSchema"
import { fetchJson } from "utils/network"

import { IFunction } from "."
import { functionKeys } from "./keys"
import { DEFAULT_REQUEST_FUNCTION_PARAMS } from "./useFunctionMutation"

type TQueryParams = operations["CatFunktionen_GetList"]["parameters"]["query"] &
  TODataParams

type TData = IFunction[]
type TResponse = AxiosResponse<TData>
type THook = (
  params?: Partial<TQueryParams>,
  options?: UseInfiniteQueryOptions<TResponse>
) => UseInfiniteQueryResult<TResponse>

type TGetFunctionsParams = {
  pageParam?: {
    recordsToSkip: number
  }
  queryKey: [string, TQueryParams]
}

function getFunctionsInfinite({
  pageParam = {
    recordsToSkip: 0,
  },
  queryKey,
}: TGetFunctionsParams) {
  const [, queryParams] = queryKey
  const { recordsToSkip } = pageParam

  return fetchJson<IFunction[], true, TQueryParams>("catfunktionen", {
    queryParams: {
      ...queryParams,
      $skip: recordsToSkip,
      $inlinecount: "allpages",
    },
    fullResponse: true,
  })
}

export const useFunctionsInfinite: THook = (params, options) => {
  const paramsWithDefaults = { ...DEFAULT_REQUEST_FUNCTION_PARAMS, ...params }

  return useInfiniteQuery(
    functionKeys.allInfinite(paramsWithDefaults),
    // @ts-ignore
    getFunctionsInfinite,
    {
      getNextPageParam: (lastPage, allPages) => {
        const recordsToSkip = allPages.reduce((accumulator, page) => {
          accumulator += page.data.length
          return accumulator
        }, 0)
        if (recordsToSkip >= Number(lastPage.headers.totalcount))
          return undefined

        return { recordsToSkip }
      },
      ...options,
    }
  )
}
