import { useQuery, UseQueryOptions, UseQueryResult } from "react-query"

import { IUser } from "types"
import { fetchJson } from "utils/network"

type TData = IUser
type THook = (options?: UseQueryOptions<TData>) => UseQueryResult<TData>

export const CURRENT_USER_KEY = "CURRENT_USER"

export const useCurrentUser: THook = (options) =>
  useQuery(
    CURRENT_USER_KEY,
    // @ts-ignore
    () => fetchJson<TData>("benutzer/current"),
    // @ts-ignore
    options
  )
