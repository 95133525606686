// @ts-ignore
import { RadioGroup as RadioGroupBase } from "@headlessui/react"
import classNames from "classnames"
import { useEffect, useState } from "react"

import { Pencil as PencilIcon } from "assets/icons"

type TOptionBase<TId> = {
  id: TId
  label?: string
  className?: string
}

type TProps<TId, TOption> = {
  options: Array<TOption & TOptionBase<TId>>
  onChange: (id: NonNullable<TId>) => void
  label: string
  checkedClassNames?: (option: TOption & TOptionBase<TId>) => string
  preselectedId?: TId
  clearable?: boolean
  editable?: boolean
  onEditButtonClick?: (id: TId) => void
  disabled?: boolean
  disabledStyle?: string
  vertical?: boolean
  wrapperClassName?: string
}

export default function RadioGroup<
  TId extends number | string,
  TOption = Record<string, unknown>
>(props: TProps<TId, TOption>): JSX.Element {
  const {
    label: screenReaderLabel,
    options,
    onChange,
    preselectedId,
    checkedClassNames,
    clearable,
    editable,
    onEditButtonClick,
    disabled,
    disabledStyle = "",
    vertical,
    wrapperClassName = "",
  } = props

  const [selectedId, setSelectedId] = useState<TId | null>(null)

  useEffect(() => {
    if (preselectedId) {
      setSelectedId(preselectedId)
    }

    return () => setSelectedId(null)
  }, [preselectedId])

  return (
    <RadioGroupBase
      value={selectedId}
      {...{ disabled }}
      onChange={(id: TId) => {
        if (id) {
          onChange(id as NonNullable<TId>)
        }
        setSelectedId(id)
      }}
    >
      <RadioGroupBase.Label className="sr-only">
        {screenReaderLabel}
      </RadioGroupBase.Label>

      <div
        className={classNames([
          "flex",
          vertical ? "flex-col" : null,
          vertical ? null : "space-x-2",
          wrapperClassName,
        ])}
      >
        {options.map((option) => {
          const { id, label, className = "" } = option

          return (
            <button
              data-id={id}
              className={classNames([
                "flex cursor-default",
                "items-center",
                className,
                editable && id === selectedId ? "bg-blue-100" : null,
              ])}
              key={id}
              {...{ disabled }}
              onClick={() => {
                if (clearable && id === selectedId) {
                  onChange(id as NonNullable<TId>)
                  setSelectedId(null)
                }
              }}
              type="button"
            >
              <RadioGroupBase.Option
                key={id}
                value={id}
                className={({ checked }) =>
                  classNames([
                    "h-4 w-4",
                    "rounded-full border border-black",
                    label ? "mr-2" : null,
                    disabled
                      ? disabledStyle || "border-gray-600 bg-gray-200"
                      : null,
                    disabled ? "cursor-default" : "cursor-pointer",
                    checked
                      ? checkedClassNames
                        ? checkedClassNames(option)
                        : "bg-blue-500"
                      : "bg-white",
                  ])
                }
              />
              {label && <RadioGroupBase.Label>{label}</RadioGroupBase.Label>}
              {editable && onEditButtonClick && (
                <div
                  role="button"
                  tabIndex={0}
                  title="edit option"
                  className="-m-2 ml-auto p-2"
                  onClick={() => onEditButtonClick(id)}
                  onKeyDown={() => onEditButtonClick(id)}
                >
                  <PencilIcon className="icon" />
                </div>
              )}
            </button>
          )
        })}
      </div>
    </RadioGroupBase>
  )
}
